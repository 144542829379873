<template>
  <div id="about">
    <b-container fluid class="pt-4 px-5">
      <b-row>
        <b-col cols="6" offset="3" class="text-center">
          <h1 class="about-head">About Us</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" class="pb-4">
          <p class="about-lead">
            ABPOOLSVA IS A FAMILY-OWNED SMALL BUSINESS WITH OVER 10 YEARS
            EXPERIENCE.
          </p>
          <div class="text-center pt-4">
            <b-img fluid src="../assets/images/satisfaction.png"></b-img>
          </div>
        </b-col>
        <b-col md="8">
          <p>
            We honestly believe that we are the luckiest small business
            around... we are passionate about what we do, and it just so happens
            that what we do helps to create memories that can last a lifetime.
          </p>
          <p>
            But we know that the decision to get a pool can be difficult. That's
            why our mission is simple.
            <span style="color:#307181"
              >Give you the opportunity to transform your backyard into a
              private oasis without breaking your budget.</span
            >
          </p>
          <p>
            Whether you're interested in a new pool or servicing an existing
            one, we are focused on providing you with professional services and
            quality products that prioritize safety and respecting your
            property.
          </p>
          <p>
            We offer free consultations and look forward to the opportunity to
            help with all your pool or spa-related needs. Give us a call or send
            us a note to get started!
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "About",

  components: {},

  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped lang="scss">
.about-head {
  font-size: $base-font-size * 2.5;
  font-family: "Roboto", sans-serif;
}

.about-lead {
  font-size: $base-font-size;
  font-family: "Lato", sans-serif;
  color: #307181;
  font-weight: bold;
}
</style>
